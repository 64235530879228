import axios from "axios";

import { clearCookieAndStorage } from "../common/utils";

import { addSignatureFn } from "./interceptors";

axios.defaults.withCredentials = true;

const ApiService = {};

ApiService.get = (url, options = {}) => {
    return axios.get(url, {
        withCredentials: options?.withCredentials ?? true,
        params: options.params,
        headers: options.headers,
        responseType: options?.responseType ?? "json",
    });
};

ApiService.post = (url, options = {}) => {
    return axios.post(url, options.data, {
        headers: options.headers,
        params: options.params,
        ...(options?.validateStatus
            ? { validateStatus: options.validateStatus }
            : {}),
    });
};

ApiService.put = (url, options) => {
    return axios.put(url, options.data, {
        withCredentials: options?.withCredentials ?? true,
        headers: options.headers,
        params: options.params,
    });
};

ApiService.patch = (url, options = {}) => {
    return axios.patch(url, options.data, {
        headers: options.headers,
        params: options.params,
    });
};

ApiService.delete = (url, options = {}) => {
    return axios.delete(url, {
        data: options.data,
        headers: options.headers,
        params: options.params,
    });
};

ApiService.axios = options => {
    return axios(options);
};

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (401 === (error.response && error.response.status)) {
            if (
                !error.response.config.url.includes("authentication") &&
                !error.response.config.url.includes("session")
            ) {
                clearCookieAndStorage();
                window.location.pathname = "/auth/login";
            }
        }
        if (403 === (error.response && error.response.status)) {
            window.location.pathname = "/org/choose-org";
        }
        return Promise.reject(error);
    },
);

axios.interceptors.request.use(addSignatureFn({}));

export default ApiService;
