import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import PrivateRoute from "common/components/PrivateRoute";

import routes from "./routes";
import Loader from "./common/components/Loader";

const NotFound = lazy(() => import("./pages/NotFound"));

const SuspenseDiv = styled.div`
    height: 100%;
    background: var(--bg-color);
`;
const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
    display: flex;
`;

const App = () => {
    return (
        <Wrapper data-testid="app">
            <Suspense
                fallback={
                    <SuspenseDiv>
                        <Loader />
                    </SuspenseDiv>
                }
            >
                <BrowserRouter>
                    <Switch>
                        {routes.map(route => (
                            <PrivateRoute
                                component={route.component}
                                guard={route.guard}
                                path={route.path}
                                exact={route.exact}
                                key={route.path}
                            />
                        ))}
                        <Route component={NotFound} />
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </Wrapper>
    );
};

export default App;
