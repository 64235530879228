import { createGlobalStyle } from "styled-components";

export const Breakpoints = {
    mobileXS: 480,
    mobile: 768,
    tablet: 1024,
    laptop: 1200,
    desktop: 1440,
};

export const SideNavBarWidth = `250px`;
export const GlobalCss = `
:root {
    /* Gradients */
     --brand-gradient: linear-gradient(95.78deg, #902EF2 0%, #FF62B7 100%);
     --brand-gradient-reverse: linear-gradient(95.78deg, #FF62B7 0%, #902EF2 100%);
     --profile-gradient: linear-gradient(95.78deg, #902EF2 0%, #FF62B7 100%);
     --button-gradient: var(--brand-gradient);
     --border-gradient: var(--brand-gradient);
    /* Public properties gradient */
     --primary-erase: #d92a7e;
     --brand-gradient-erase: linear-gradient(120.55deg, #FF4040 18.56%, #A20BD8 98.01%);
     --brand-gradient-erase-reverse: linear-gradient(120.55deg, #A20BD8 18.56%, #FF4040 98.01%);
     --primary-upscale: #17C969;
     --brand-gradient-upscale: linear-gradient(107.65deg,#17C969 17.53%,#005DBB 94.64%);
     --brand-gradient-upscale-reverse: linear-gradient(107.65deg,#005DBB 17.53%,#17C969 94.64%);
     --primary-watermark: #F9D423;
     --brand-gradient-watermark: linear-gradient(132.2deg, #F9D423 7.64%, #FF4E50 97.11%);
     --brand-gradient-watermark-reverse: linear-gradient(132.2deg, #FF4E50 7.64%, #F9D423 97.11%);
     --primary-shrink: #AD00FF;
     --brand-gradient-shrink:linear-gradient(90.36deg, #AD00FF 0.31%, #330371 104.9%);
     --brand-gradient-shrink-reverse: linear-gradient(120.55deg, #330371 18.56%, #AD00FF 98.01%);
    /* Primary Colors */
     --primary-dark: #6212B2;
     --primary: #902EF2;
     --primary-light: #B264FF;
     --primary-hover: #8110F1;
     --primary-main: #902EF2;
    /* Secondary Color */
     --secondary-dark: #E83094;
     --secondary: #FF62B7;
     --secondary-light: #FF99D0;
    /* tertiary color */
     --tertiary : rgba(255, 255, 255, 0);
    /* icon-hover-bg */
     --icon-hover-bg: #323648;
    /* light colors */
     --white: #FFFFFF;
     --light-100: #ECECF9;
     --light-200: #C6CFE2;
     --light-300: #AAABC5;
     --light-400: #8A8AA8;
    /* dark colors */
     --black: #0B0E10;
     --dark-900: #171921;
     --dark-800: #1D202A;
     --dark-801: #0A0D10;
     --dark-700: #242B38;
     --dark-600: #323648;
    /* Status Colors */
     --success-dark: #1B934B;
     --success: #1FBE5F;
     --success-light: #24E070;
     --danger-dark: #D42B4A;
     --danger: #E83F5D;
     --danger-light: #FD4969;
     --danger-hover: #FE2F54;
     --info: #87cefa;
     --partial-success: #FFA500;
     --warning: #ffff99;
     --error: #ff3333;
     --divider: #dbdeff29;
    /* Talk to Expert Card */
     --purple-1: #5c2d96;
    /* Border */
     --border-dark: rgba(203, 205, 254, 0.16);
     --border-light: rgba(203, 205, 254, 0.22);
     --border: #323232;
    /* light colors */
     --light-bg-1: rgba(196, 211, 249, 0.07);
     --light-bg-1-hover: rgba(255, 255, 255, 0.11);
    /* Border radius */
     --border-radius-20: 20px;
     --border-radius-16: 16px;
     --border-radius-12: 12px;
     --border-radius-10: 10px;
     --border-radius-8: 8px;
     --border-radius-6: 6px;
     --border-radius-4: 4px;
    /* Letter spacing */
     --letter-spacing-0: 0%;
     --letter-spacing-1: 1%;
     --letter-spacing-15: 1.5%;
     --letter-spacing-1N: -1%;
     --letter-spacing-05N: -0.5%;
     --letter-spacing-15N: -1.5%;
     --h1-letter-spacing-d: 0.5px;
     --h1-letter-spacing-m: 0.2px;
     --h2-letter-spacing-d: 0.5px;
     --h2-letter-spacing-m: 0.2px;
     --h3-letter-spacing-d: 0.5px;
     --h3-letter-spacing-m: 0.2px;
    /* Font */
     --heading-font : "NitrozenType", Inter, sans-serif;
     --body-font : "NitrozenType", Inter, sans-serif;
    /* typography */
     --h1-d: 56px;
     --h1-m: 40px;
     --h2-d: 40px;
     --h2-m: 32px;
     --h3-d: 32px;
     --h3-m: 24px;
     --subtitle-1-d: 24px;
     --subtitle-1-m: 20px;
     --subtitle-2-d: 20px;
     --subtitle-2-m: 18px;
     --subtitle-3-d: 18px;
     --subtitle-3-m: 16px;
     --subtitle-4-d: 16px;
     --subtitle-4-m: 14px;
     --lead-body-d: 18px;
     --lead-body-m: 16px;
     --body-lead-d: 18px;
     --body-lead-m: 18px;
     --body-1-d: 16px;
     --body-1-m: 15px;
     --body-2-d: 14px;
     --body-2-m: 13px;
     --body-3-d: 12px;
     --body-3-m: 11px;
     --body-4-d: 12px;
     --body-4-m: 10px;
     --caption-1-d: 14px;
     --caption-1-m: 12px;
    /* Button font size */
     --button: 16px;
     --button-small: 14px;
    /* Font weight */
     --font-weight-600: 600;
     --font-weight-400: 400;
     --bold: 700;
     --semi-bold: 600;
     --regular: 400;
    /* Line height */
     --line-height-140: 140%;
     --line-height-120: 120%;
     --line-height-62: 62px;
     --line-height-58: 58px;
     --line-height-56: 56px;
     --line-height-49: 49px;
     --line-height-40: 40px;
     --line-height-36: 36px;
     --line-height-34: 34px;
     --line-height-32: 32px;
     --line-height-29: 29px;
     --line-height-28: 28px;
     --line-height-25: 25px;
     --line-height-24: 24px;
     --line-height-22: 22px;
     --line-height-21: 21px;
     --line-height-19: 19px;
     --line-height-16: 16px;
    /* Others */
     --bg-color: #101112;
     --secondary-bg-color: rgba(255, 255, 255, 0.02);
     --blue-bg: rgba(94, 177, 255, 1);
     --bg-color-80: rgba(16,17,18,0.8);
     --error: #FF5858;
     --secondary-border-color: #50ABFF;
     --button-border: #d92a7e;
     --secondary-button-border: #323232;
     --banner-title-max-width: 1039px;
     --banner-description-max-width: 795px;
     --section-description-max-width: 795px;
     --content-max-width: 498px;
     --font-size-base: 14px ;
    /* Assumes the browser, typically 16px */
     --font-size-sm: 12px;
     --font-size-lg: 16px;
     --bg-error: #E53F64;
     --error: #E53F64;
     --dropdown-bg: #28292A;
     --dark: #101112;
     --light: var(--white-100);
     --disabled: rgba(97,97,97);
    /* Z-index */
     --ZIndex3:100000;
    /* Refs */
    /* https://ilxanlar.medium.com/you-shouldnt-rely-on-css-100vh-and-here-s-why-1b4721e74487 */
    /* https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97 */
     --doc-height: 100%;
    /* later updated by javascript to use window.innerHeight, ScriptHooks.js */
}
/* p {
     */
    /* -webkit-user-select: text;
     */
    /* -khtml-user-select: text;
     */
    /* -moz-user-select: text;
     */
    /* -o-user-select: text;
     */
    /* user-select: text;
     */
    /*
}
 */
/* code {
     */
    /* -webkit-user-select: text;
     */
    /* -khtml-user-select: text;
     */
    /* -moz-user-select: text;
     */
    /* -o-user-select: text;
     */
    /* user-select: text;
     */
    /*
}
 */
 html {
     height: 100%;
}
 body {
     height: 100%;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     background-color: var(--white);
     font-family: var(--body-font);
     color: var(--black);
     .n-toast-container-main .n-toast-wrapper .n-toast-left-container .n-toast-header {
         overflow: auto;
         text-overflow: inherit;
         white-space: normal;
    }
    .loader, .loader:before, .loader:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: bblFadInOut 1.8s infinite ease-in-out;
      }
      .loader {
        color: #FFF;
        font-size: 7px;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
      }
      .loader:before,
      .loader:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .loader:before {
        left: -3.5em;
        animation-delay: -0.32s;
      }
      .loader:after {
        left: 3.5em;
      }

      @keyframes bblFadInOut {
        0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
        40% { box-shadow: 0 2.5em 0 0 }
      }

     .n-input-group-disabled{
         opacity:0.7;
         svg{
             color: #B5B5B5;
        }
        .n-input-container:hover{
            border:1px solid #e0e0e0;
        }
    }
     .n-input-label, .n-dropdown-label {
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.005em;
         color: rgba(0, 0, 0, 0.65);
    }
    .n-focused-border{
        border:1px solid #000093 !important;
    }
    .n-form-input{
        .n-input-label-container, .n-input-underinfo{
            margin-left: 8px;
        }
        
    }
     .n-input-container {
         border: 1px solid #e0e0e0;
         padding: 0.8rem 1.2rem;
         :hover{
            border:1px solid #000093;
         }
         .n-input{
             font-weight: 500;
             font-size: 16px;
             line-height: 24px;
             letter-spacing: -0.005em;
             color:#141414;
             :disabled {
                 background: #fff;
                 color: #B5B5B5;
                 opacity:1;
            }
            ::placeholder{
                color: var(--Neutral-Grey-60, #B5B5B5);
                font-size: 14px;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                letter-spacing: -0.07px;
            }
        }
        .n-suffix-position {
            top: 0.6rem;
            right: 1.2rem;
        }
       
    }
     .n-dropdown-container.disabled{
         opacity:0.7;
         .n-select__trigger,svg{
             color:#B5B5B5 !important;
        }
    }
     .n-dropdown-container{
        .n-select-wrapper{
            user-select: unset;
        }
         .n-select__trigger{
             font-weight: 500;
             font-size: 16px;
             line-height: 24px;
             letter-spacing: -0.005em;
             color:#141414;
             border: 1px solid #e0e0e0;
             height: 42px;
             padding: 1rem 1.2rem;
             span{
                 font-family: var(--PrimaryFont, "NitrozenType", helvetica, arial, sans-serif);
            }
        }
        .n-dropdown-label{
            margin-left: 8px;
        }
        .n-helper-text{
            color: var(--Light-Primary-Grey-80, var(--Neutral-Grey-80, rgba(0, 0, 0, 0.65)));
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.07px;
        }
    }
    .n-autocomplete-wrapper {
        .n-autocomplete{
            height:4.2rem;
            border: 1px solid #e0e0e0;
            :hover{
                border: 1px solid #000093;
            }
            .n-pre-input{
                ::placeholder{
                    color: var(--Neutral-Grey-60, #B5B5B5);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: -0.07px;
                }
            }
        }
        .n-autocomplete.n-autocomplete-focussed{
            border: 1px solid #000093;
        }
        
    }
    .rounded-24{
        border-radius:24px !important;
        .n-pre-input,.n-select__trigger,.n-input-container{
            border-radius:24px !important;
        }
    }
    .n-tab-container.n-tab-navbar .n-tab .n-d-scroll{
        background-color: #3535F3;
    }
    .n-pagination-top-container{
        .n-pagination__select{
            .n-select__trigger{
                border-radius:24px;
            }
            .n-options {
                z-index:2;
            }
        }
        .n-pagination__main{
            .n-input-container{
                border-radius:24px;
                .n-input {
                    min-width: 4.5ch;
                    width: 4.5ch;
                    max-width: 4.5ch;
                  }
            }
        }
        .n-pagination__count{
            min-width:unset;
        }
    }
    
    .fds-modal-container{
        top: 50%;
        transform: translateY(-50%);
        .fds-modal-body{
            overflow-y:auto;
        }
    }

    .n-main-table{
        user-select:unset;
    }
    .notification-container.fds-toast-pos-top-right{
        z-index: 100000
    }
}
 a {
     text-decoration: none;
}
 * {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     font-family: var(--body-font);
     letter-spacing: var(--letter-spacing);
     scroll-behavior: smooth;
}
`;

export const GlobalStyle = createGlobalStyle`
    ${GlobalCss}
`;
