export const SCOPES_LIST = [
    {
        text: "Owner",
        value: "owner",
    },
    {
        text: "Admin",
        value: "admin",
    },
    {
        text: "Supervisor",
        value: "supervisor",
    },
    {
        text: "Content Acquirer",
        value: "CA",
    },
    {
        text: "Content Enricher",
        value: "CE",
    },
    {
        text: "Auditor",
        value: "auditor",
    },
];

export const ACCESS_SCOPES = Object.freeze({
    OWNER: "owner",
    ADMIN: "admin",
    SUPERVISOR: "supervisor",
    CA: "CA",
    CE: "CE",
    AUDITOR: "auditor",
    ALL: ["owner", "admin", "supervisor", "CA", "CE", "auditor"],
});

export const ORG_TYPE = Object.freeze({
    BRAND: "seller",
    VENDOR: "vendor",
    ALL: ["seller", "vendor"],
});

export const DEBOUNCE_TIME = 500;

export const URL_REGEX =
    /(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!ftp))(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?(?::\d{2,5})?(?:[/?#]\S*)?/i;

export const SPECIAL_CHARS_REGEX = /[\[\]\(\)\*\?\\\%\#\$\^\&\.\|\+]/g;
export const SPECIAL_CHARS = "[,],(,),*,?,\\,%,#,$,^,&,.,|,+";
